/* eslint-disable */
import { sortBy } from "lodash"


export enum SynTraitPlayer {
    FRIENDLY = "FRIENDLY",
    ENEMY = "ENEMY",
    ANY = "ANY",
}

export class SynTraitPlayerUtils {
    static readonly values = [SynTraitPlayer.FRIENDLY, SynTraitPlayer.ENEMY, SynTraitPlayer.ANY]
    static valueOf = (value: string) => SynTraitPlayerUtils.values.find(enumValue => enumValue === value)
    static sort = (value: SynTraitPlayer[]) => sortBy(value, expansion => SynTraitPlayerUtils.values.indexOf(expansion))
}