/* eslint-disable */
import { sortBy } from "lodash"


export enum KeyForgeFormat {
    ARCHON = "ARCHON",
    TRIAD = "TRIAD",
    SURVIVAL = "SURVIVAL",
    ADAPTIVE = "ADAPTIVE",
    REVERSAL = "REVERSAL",
    SAS_CAP = "SAS_CAP",
    OTHER = "OTHER",
    MULTIPLE = "MULTIPLE",
}

export class KeyForgeFormatUtils {
    static readonly values = [KeyForgeFormat.ARCHON, KeyForgeFormat.TRIAD, KeyForgeFormat.SURVIVAL, KeyForgeFormat.ADAPTIVE, KeyForgeFormat.REVERSAL, KeyForgeFormat.SAS_CAP, KeyForgeFormat.OTHER, KeyForgeFormat.MULTIPLE]
    static valueOf = (value: string) => KeyForgeFormatUtils.values.find(enumValue => enumValue === value)
    static sort = (value: KeyForgeFormat[]) => sortBy(value, expansion => KeyForgeFormatUtils.values.indexOf(expansion))
}