/* eslint-disable */
import { sortBy } from "lodash"


export enum OfferStatus {
    SENT = "SENT",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
}

export class OfferStatusUtils {
    static readonly values = [OfferStatus.SENT, OfferStatus.ACCEPTED, OfferStatus.REJECTED, OfferStatus.CANCELED, OfferStatus.EXPIRED]
    static valueOf = (value: string) => OfferStatusUtils.values.find(enumValue => enumValue === value)
    static sort = (value: OfferStatus[]) => sortBy(value, expansion => OfferStatusUtils.values.indexOf(expansion))
}