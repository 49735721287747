/* eslint-disable */
import { sortBy } from "lodash"


export enum DeckSortOptions {
    ADDED_DATE = "ADDED_DATE",
    SAS_RATING = "SAS_RATING",
    NAME = "NAME",
}

export class DeckSortOptionsUtils {
    static readonly values = [DeckSortOptions.ADDED_DATE, DeckSortOptions.SAS_RATING, DeckSortOptions.NAME]
    static valueOf = (value: string) => DeckSortOptionsUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DeckSortOptions[]) => sortBy(value, expansion => DeckSortOptionsUtils.values.indexOf(expansion))
}