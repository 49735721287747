/* eslint-disable */
import { sortBy } from "lodash"


export enum TournamentStage {
    TOURNAMENT_NOT_STARTED = "TOURNAMENT_NOT_STARTED",
    PAIRING_IN_PROGRESS = "PAIRING_IN_PROGRESS",
    GAMES_IN_PROGRESS = "GAMES_IN_PROGRESS",
    VERIFYING_ROUND_RESULTS = "VERIFYING_ROUND_RESULTS",
    TOURNAMENT_COMPLETE = "TOURNAMENT_COMPLETE",
}

export class TournamentStageUtils {
    static readonly values = [TournamentStage.TOURNAMENT_NOT_STARTED, TournamentStage.PAIRING_IN_PROGRESS, TournamentStage.GAMES_IN_PROGRESS, TournamentStage.VERIFYING_ROUND_RESULTS, TournamentStage.TOURNAMENT_COMPLETE]
    static valueOf = (value: string) => TournamentStageUtils.values.find(enumValue => enumValue === value)
    static sort = (value: TournamentStage[]) => sortBy(value, expansion => TournamentStageUtils.values.indexOf(expansion))
}