/* eslint-disable */
import { sortBy } from "lodash"


export enum UserType {
    ADMIN = "ADMIN",
    CONTENT_CREATOR = "CONTENT_CREATOR",
    USER = "USER",
}

export class UserTypeUtils {
    static readonly values = [UserType.ADMIN, UserType.CONTENT_CREATOR, UserType.USER]
    static valueOf = (value: string) => UserTypeUtils.values.find(enumValue => enumValue === value)
    static sort = (value: UserType[]) => sortBy(value, expansion => UserTypeUtils.values.indexOf(expansion))
}