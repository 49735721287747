export const aercProperties = [
    "amberControl",
    "expectedAmber",
    "artifactControl",
    "creatureControl",
    "effectivePower",
    "efficiency",
    "recursion",
    "disruption",
    "creatureProtection",
    "other"
]

