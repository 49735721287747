/* eslint-disable */
import { sortBy } from "lodash"


export enum DeckLanguage {
    ENGLISH = "ENGLISH",
    FRENCH = "FRENCH",
    ITALIAN = "ITALIAN",
    GERMAN = "GERMAN",
    SPANISH = "SPANISH",
    POLISH = "POLISH",
    CHINESE = "CHINESE",
    THAI = "THAI",
    RUSSIAN = "RUSSIAN",
    KOREAN = "KOREAN",
    OTHER = "OTHER",
}

export class DeckLanguageUtils {
    static readonly values = [DeckLanguage.ENGLISH, DeckLanguage.FRENCH, DeckLanguage.ITALIAN, DeckLanguage.GERMAN, DeckLanguage.SPANISH, DeckLanguage.POLISH, DeckLanguage.CHINESE, DeckLanguage.THAI, DeckLanguage.RUSSIAN, DeckLanguage.KOREAN, DeckLanguage.OTHER]
    static valueOf = (value: string) => DeckLanguageUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DeckLanguage[]) => sortBy(value, expansion => DeckLanguageUtils.values.indexOf(expansion))
}