/* eslint-disable */
import { sortBy } from "lodash"


export enum PublicityType {
    PUBLIC = "PUBLIC",
    NOT_SEARCHABLE = "NOT_SEARCHABLE",
    BULK_SALE = "BULK_SALE",
    PRIVATE = "PRIVATE",
}

export class PublicityTypeUtils {
    static readonly values = [PublicityType.PUBLIC, PublicityType.NOT_SEARCHABLE, PublicityType.BULK_SALE, PublicityType.PRIVATE]
    static valueOf = (value: string) => PublicityTypeUtils.values.find(enumValue => enumValue === value)
    static sort = (value: PublicityType[]) => sortBy(value, expansion => PublicityTypeUtils.values.indexOf(expansion))
}