/* eslint-disable */
import { sortBy } from "lodash"


export enum DeckType {
    STANDARD = "STANDARD",
    ALLIANCE = "ALLIANCE",
}

export class DeckTypeUtils {
    static readonly values = [DeckType.STANDARD, DeckType.ALLIANCE]
    static valueOf = (value: string) => DeckTypeUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DeckType[]) => sortBy(value, expansion => DeckTypeUtils.values.indexOf(expansion))
}