/* eslint-disable */
import { sortBy } from "lodash"


export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export class SortDirectionUtils {
    static readonly values = [SortDirection.ASC, SortDirection.DESC]
    static valueOf = (value: string) => SortDirectionUtils.values.find(enumValue => enumValue === value)
    static sort = (value: SortDirection[]) => sortBy(value, expansion => SortDirectionUtils.values.indexOf(expansion))
}