/* eslint-disable */
import { sortBy } from "lodash"


export enum DifferenceAmount {
    MINIMAL = "MINIMAL",
    MODERATE = "MODERATE",
    SIGNIFICANT = "SIGNIFICANT",
}

export class DifferenceAmountUtils {
    static readonly values = [DifferenceAmount.MINIMAL, DifferenceAmount.MODERATE, DifferenceAmount.SIGNIFICANT]
    static valueOf = (value: string) => DifferenceAmountUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DifferenceAmount[]) => sortBy(value, expansion => DifferenceAmountUtils.values.indexOf(expansion))
}