/* eslint-disable */
import { sortBy } from "lodash"


export enum Rarity {
    Common = "Common",
    Uncommon = "Uncommon",
    Rare = "Rare",
    Variant = "Variant",
    FIXED = "FIXED",
    Special = "Special",
    EvilTwin = "EvilTwin",
    Token = "Token",
}

export class RarityUtils {
    static readonly values = [Rarity.Common, Rarity.Uncommon, Rarity.Rare, Rarity.Variant, Rarity.FIXED, Rarity.Special, Rarity.EvilTwin, Rarity.Token]
    static valueOf = (value: string) => RarityUtils.values.find(enumValue => enumValue === value)
    static sort = (value: Rarity[]) => sortBy(value, expansion => RarityUtils.values.indexOf(expansion))
}