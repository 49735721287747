/* eslint-disable */
import { sortBy } from "lodash"


export enum EventTimeRange {
    PAST = "PAST",
    FUTURE = "FUTURE",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_THREE_MONTHS = "NEXT_THREE_MONTHS",
}

export class EventTimeRangeUtils {
    static readonly values = [EventTimeRange.PAST, EventTimeRange.FUTURE, EventTimeRange.NEXT_MONTH, EventTimeRange.NEXT_THREE_MONTHS]
    static valueOf = (value: string) => EventTimeRangeUtils.values.find(enumValue => enumValue === value)
    static sort = (value: EventTimeRange[]) => sortBy(value, expansion => EventTimeRangeUtils.values.indexOf(expansion))
}