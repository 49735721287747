/* eslint-disable */
import { sortBy } from "lodash"


export enum AllianceDeckSortOptions {
    ADDED_DATE = "ADDED_DATE",
    SAS_RATING = "SAS_RATING",
    NAME = "NAME",
}

export class AllianceDeckSortOptionsUtils {
    static readonly values = [AllianceDeckSortOptions.ADDED_DATE, AllianceDeckSortOptions.SAS_RATING, AllianceDeckSortOptions.NAME]
    static valueOf = (value: string) => AllianceDeckSortOptionsUtils.values.find(enumValue => enumValue === value)
    static sort = (value: AllianceDeckSortOptions[]) => sortBy(value, expansion => AllianceDeckSortOptionsUtils.values.indexOf(expansion))
}