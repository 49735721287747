/* eslint-disable */
import { sortBy } from "lodash"


export enum UserImgTag {
    EVENT_BANNER = "EVENT_BANNER",
}

export class UserImgTagUtils {
    static readonly values = [UserImgTag.EVENT_BANNER]
    static valueOf = (value: string) => UserImgTagUtils.values.find(enumValue => enumValue === value)
    static sort = (value: UserImgTag[]) => sortBy(value, expansion => UserImgTagUtils.values.indexOf(expansion))
}