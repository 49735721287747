/* eslint-disable */
import { sortBy } from "lodash"


export enum PatreonRewardsTier {
    NOTICE_BARGAINS = "NOTICE_BARGAINS",
    SUPPORT_SOPHISTICATION = "SUPPORT_SOPHISTICATION",
    MERCHANT_AEMBERMAKER = "MERCHANT_AEMBERMAKER",
    ALWAYS_GENEROUS = "ALWAYS_GENEROUS",
}

export class PatreonRewardsTierUtils {
    static readonly values = [PatreonRewardsTier.NOTICE_BARGAINS, PatreonRewardsTier.SUPPORT_SOPHISTICATION, PatreonRewardsTier.MERCHANT_AEMBERMAKER, PatreonRewardsTier.ALWAYS_GENEROUS]
    static valueOf = (value: string) => PatreonRewardsTierUtils.values.find(enumValue => enumValue === value)
    static sort = (value: PatreonRewardsTier[]) => sortBy(value, expansion => PatreonRewardsTierUtils.values.indexOf(expansion))
}