/* eslint-disable */
import { sortBy } from "lodash"


export enum PlayZone {
    deck = "deck",
    hand = "hand",
    play = "play",
    discard = "discard",
    purged = "purged",
    archives = "archives",
}

export class PlayZoneUtils {
    static readonly values = [PlayZone.deck, PlayZone.hand, PlayZone.play, PlayZone.discard, PlayZone.purged, PlayZone.archives]
    static valueOf = (value: string) => PlayZoneUtils.values.find(enumValue => enumValue === value)
    static sort = (value: PlayZone[]) => sortBy(value, expansion => PlayZoneUtils.values.indexOf(expansion))
}