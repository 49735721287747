/* eslint-disable */
import { sortBy } from "lodash"


export enum SynTraitHouse {
    anyHouse = "anyHouse",
    house = "house",
    outOfHouse = "outOfHouse",
    continuous = "continuous",
}

export class SynTraitHouseUtils {
    static readonly values = [SynTraitHouse.anyHouse, SynTraitHouse.house, SynTraitHouse.outOfHouse, SynTraitHouse.continuous]
    static valueOf = (value: string) => SynTraitHouseUtils.values.find(enumValue => enumValue === value)
    static sort = (value: SynTraitHouse[]) => sortBy(value, expansion => SynTraitHouseUtils.values.indexOf(expansion))
}