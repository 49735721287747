/* eslint-disable */
import { sortBy } from "lodash"


export enum DeckCondition {
    NEW_IN_PLASTIC = "NEW_IN_PLASTIC",
    NEAR_MINT = "NEAR_MINT",
    PLAYED = "PLAYED",
    HEAVILY_PLAYED = "HEAVILY_PLAYED",
}

export class DeckConditionUtils {
    static readonly values = [DeckCondition.NEW_IN_PLASTIC, DeckCondition.NEAR_MINT, DeckCondition.PLAYED, DeckCondition.HEAVILY_PLAYED]
    static valueOf = (value: string) => DeckConditionUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DeckCondition[]) => sortBy(value, expansion => DeckConditionUtils.values.indexOf(expansion))
}