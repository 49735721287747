/* eslint-disable */
import { sortBy } from "lodash"


export enum MailCategory {
    INBOX = "INBOX",
    SENT = "SENT",
    UNREAD = "UNREAD",
    ALL_MAIL = "ALL_MAIL",
    ARCHIVED = "ARCHIVED",
}

export class MailCategoryUtils {
    static readonly values = [MailCategory.INBOX, MailCategory.SENT, MailCategory.UNREAD, MailCategory.ALL_MAIL, MailCategory.ARCHIVED]
    static valueOf = (value: string) => MailCategoryUtils.values.find(enumValue => enumValue === value)
    static sort = (value: MailCategory[]) => sortBy(value, expansion => MailCategoryUtils.values.indexOf(expansion))
}