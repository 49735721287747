/* eslint-disable */
import { sortBy } from "lodash"


export enum TournamentVisibility {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    TEAM = "TEAM",
}

export class TournamentVisibilityUtils {
    static readonly values = [TournamentVisibility.PUBLIC, TournamentVisibility.PRIVATE, TournamentVisibility.TEAM]
    static valueOf = (value: string) => TournamentVisibilityUtils.values.find(enumValue => enumValue === value)
    static sort = (value: TournamentVisibility[]) => sortBy(value, expansion => TournamentVisibilityUtils.values.indexOf(expansion))
}