/* eslint-disable */
import { sortBy } from "lodash"


export enum UserSort {
    USER_NAME = "USER_NAME",
    RATING = "RATING",
    DECK_COUNT = "DECK_COUNT",
    PATRON_LEVEL = "PATRON_LEVEL",
    SAS_AVERAGE = "SAS_AVERAGE",
    TOP_SAS = "TOP_SAS",
    LOW_SAS = "LOW_SAS",
    TOTAL_POWER = "TOTAL_POWER",
    TOTAL_CHAINS = "TOTAL_CHAINS",
    FOR_SALE_COUNT = "FOR_SALE_COUNT",
}

export class UserSortUtils {
    static readonly values = [UserSort.USER_NAME, UserSort.RATING, UserSort.DECK_COUNT, UserSort.PATRON_LEVEL, UserSort.SAS_AVERAGE, UserSort.TOP_SAS, UserSort.LOW_SAS, UserSort.TOTAL_POWER, UserSort.TOTAL_CHAINS, UserSort.FOR_SALE_COUNT]
    static valueOf = (value: string) => UserSortUtils.values.find(enumValue => enumValue === value)
    static sort = (value: UserSort[]) => sortBy(value, expansion => UserSortUtils.values.indexOf(expansion))
}