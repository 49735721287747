import IconButton from "@material-ui/core/IconButton/IconButton"
import { observer } from "mobx-react"
import * as React from "react"
import { themeStore } from "../../config/MuiConfig"
import { Routes } from "../../config/Routes"

interface EvilTwinButtonProps {
    twinId?: string
}

export const EvilTwinButton = observer((props: EvilTwinButtonProps) => {

    if (props.twinId == null) {
        return null
    }

    return (
        <IconButton
            href={Routes.deckPage(props.twinId)}
            style={{margin: 0}}
        >
            <svg width="18" height="18" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M44.4041 0.069218C51.4338 5.60666 53.4747 12.5977 50.9803 20.627C48.0324 30.0406 42.9679 38.4852 36.0138 45.9607C34.6532 47.4143 32.9903 48.7987 31.3273 49.9754C27.5479 52.6749 24.4488 52.6749 20.6694 49.9754C19.082 48.8679 17.5702 47.622 16.2852 46.2376C9.02876 38.5544 3.81317 29.7637 0.865223 20.004C-1.10007 13.4283 0.260513 7.40632 5.17376 2.28419C5.92964 1.5228 7.13905 1.10749 8.1217 0.484526L8.04612 0.415308C7.81935 0.969052 7.66817 1.5228 7.44141 2.00732C3.81317 9.55208 7.36581 16.8892 15.3026 20.973C17.4947 22.0805 19.082 22.0805 20.6694 20.2116C22.2567 18.3428 23.3905 16.1278 24.6 13.982C25.2047 12.8745 25.4314 11.6978 26.0361 9.75973C26.7164 11.4902 27.0944 12.5285 27.6235 13.4975C28.7573 15.574 29.6644 17.7198 31.1006 19.5887C33.0659 22.0113 34.8044 22.1497 37.6012 20.627C44.782 16.7507 47.6544 9.69052 44.9332 2.56106C44.6309 1.73045 44.6309 0.830616 44.4797 0L44.4041 0.069218ZM27.5479 37.7238C28.7573 38.2083 29.1353 38.5544 29.5888 38.6236C38.6594 40.2849 43.875 33.1554 43.7238 27.4103C43.6482 25.9567 42.8923 25.0569 41.5318 24.5032C40.0956 23.8802 38.5838 23.6725 37.2988 24.7108C36.4673 25.3338 35.5603 26.1644 35.1823 27.1334C33.595 30.9404 31.5541 34.609 27.5479 37.7238ZM24.3732 37.6546C20.5182 34.609 18.3261 31.1481 16.9655 27.1334C16.7388 26.5105 16.2097 25.9567 15.7561 25.403C14.3199 23.8802 12.7326 23.6033 10.6917 24.3647C8.72641 25.0569 7.89494 26.4413 8.42406 28.1717C9.02876 30.3175 9.63347 32.6709 10.9941 34.5398C13.7152 38.5544 19.0064 39.8003 24.3732 37.6546Z"
                    fill={themeStore.iconColor}
                />
            </svg>
        </IconButton>
    )
})
