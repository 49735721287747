
export enum EnhancementType {
    AEMBER = "AEMBER",
    CAPTURE = "CAPTURE",
    DAMAGE = "DAMAGE",
    DRAW = "DRAW",
    DISCARD = "DISCARD",
    BROBNAR = "BROBNAR",
    DIS = "DIS",
    EKWIDON = "EKWIDON",
    GEISTOID = "GEISTOID",
    LOGOS = "LOGOS",
    MARS = "MARS",
    SKYBORN = "SKYBORN",
}
