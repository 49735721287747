/* eslint-disable */
import { sortBy } from "lodash"


export enum DeckListingStatus {
    SALE = "SALE",
    AUCTION = "AUCTION",
    COMPLETE = "COMPLETE",
}

export class DeckListingStatusUtils {
    static readonly values = [DeckListingStatus.SALE, DeckListingStatus.AUCTION, DeckListingStatus.COMPLETE]
    static valueOf = (value: string) => DeckListingStatusUtils.values.find(enumValue => enumValue === value)
    static sort = (value: DeckListingStatus[]) => sortBy(value, expansion => DeckListingStatusUtils.values.indexOf(expansion))
}