/* eslint-disable */
import { sortBy } from "lodash"


export enum CardType {
    Action = "Action",
    Artifact = "Artifact",
    Creature = "Creature",
    Upgrade = "Upgrade",
    TokenCreature = "TokenCreature",
}

export class CardTypeUtils {
    static readonly values = [CardType.Action, CardType.Artifact, CardType.Creature, CardType.Upgrade, CardType.TokenCreature]
    static valueOf = (value: string) => CardTypeUtils.values.find(enumValue => enumValue === value)
    static sort = (value: CardType[]) => sortBy(value, expansion => CardTypeUtils.values.indexOf(expansion))
}