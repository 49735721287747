/* eslint-disable */
import { sortBy } from "lodash"


export enum PairingStrategy {
    SWISS_SOS = "SWISS_SOS",
    SWISS_RANDOM = "SWISS_RANDOM",
    MANUAL_PAIRING = "MANUAL_PAIRING",
}

export class PairingStrategyUtils {
    static readonly values = [PairingStrategy.SWISS_SOS, PairingStrategy.SWISS_RANDOM, PairingStrategy.MANUAL_PAIRING]
    static valueOf = (value: string) => PairingStrategyUtils.values.find(enumValue => enumValue === value)
    static sort = (value: PairingStrategy[]) => sortBy(value, expansion => PairingStrategyUtils.values.indexOf(expansion))
}